.card {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.checkin-input-lg {
  padding: 10px 18px;
  font-size: 20px;
}
.customSelect {
  padding:6px;
  font-size: 20px;
  background-color: white;
  outline: none;
}

